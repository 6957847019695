import { defineStore } from "pinia";

export const useGlobalStateStore = defineStore("globalStateStore", {
  state: () => ({
    loader: true,
    loaderTitle: "Loading...",
    linkerModalShow: true,
    redirectLink: "",
    hasLoginError: false,
    loginDetails: {
      email: "",
      surname: "",
      dateofbirth: "",
      postcode: "",
      remember: false,
    },
    sentOTPEmail: false,
    currentView: "live",
    showSideMenu: false,
  }),
  actions: {
    startLoader(message: string) {
      this.loaderTitle = message;
      this.loader = true;
    },
    stopLoader() {
      setTimeout(() => {
        this.loader = false;
      }, 1500);
    },
  },
});
