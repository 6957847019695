<template>
  <div class="header-bar-wrapper">
    <div>
      <ul>
        <li>
          <a
            href="https://goshorty.co.uk/about-us/"
            target="_blank"
            rel="noopener"
            >About GoShorty</a
          >
        </li>
        <li>
          <a href="https://goshorty.co.uk/faqs/" target="_blank" rel="noopener"
            >FAQs</a
          >
        </li>
        <li>
          <a href="https://goshorty.co.uk/blog/" target="_blank" rel="noopener"
            >Blog</a
          >
        </li>
        <li>
          <a
            href="https://goshorty.co.uk/contact"
            target="_blank"
            rel="noopener"
            >Contact us</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
