import { defineStore } from "pinia";

export const useLinkerStore = defineStore("linkerStore", {
  state: () => ({
    linkerWebReference: "" as string,
    linkerRegistration: "" as string,
    linkerType: "" as string,
    linkerFinalLinkGenerated: false,
    linkerSubType: "" as "Quote" | "Sale",
    linkerModalShow: false,
    linkerFinalLink: "" as string | undefined,
    linkerLiveLink: "",
  }),
  actions: {
    setLinker(
      webReference: string,
      registration: string,
      type: "Recall" | "Extend",
      subType: "Quote" | "Sale"
    ) {
      this.linkerWebReference = webReference;
      this.linkerRegistration = registration;
      this.linkerSubType = subType;
      this.linkerType = type;
      this.linkerModalShow = true;
      this.linkerFinalLinkGenerated = false;
    },
  },
});
