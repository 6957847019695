import revive_payload_client_4sVQNw7RlN from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_i8AMfKeYnY from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import chunk_reload_client_UciE0i6zes from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_gtm_client_pcj1c3F7ot from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/plugins/01.gtm.client.ts";
import _02_fontawesome_zERh8wjiDO from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/plugins/02.fontawesome.ts";
import _03_toast_BKanmyNWgG from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/plugins/03.toast.ts";
import _04_sentry_client_tuXFV7LSkY from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/plugins/04.sentry.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_i8AMfKeYnY,
  chunk_reload_client_UciE0i6zes,
  _01_gtm_client_pcj1c3F7ot,
  _02_fontawesome_zERh8wjiDO,
  _03_toast_BKanmyNWgG,
  _04_sentry_client_tuXFV7LSkY
]