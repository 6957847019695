import { useSessionIdStore } from "~/stores/useSessionIdStore";
import { useCustomerStore } from "~/stores/useCustomerStore";

const eventSummaries = {
  MY0000: "[YOUR] [FE] [USER ACTION] User requested to login",
  MY0001: "[YOUR] [FE] [API RESULT] User failed to login",
  MY0002: "[YOUR] [FE] [API RESULT] User logged in successfully",
  MY0003: "[YOUR] [FE] [USER ACTION] User logged out",
  MY0004: "[YOUR] [FE] [USER ACTION] User requested to download a document",
  MY0005: "[YOUR] [FE] [USER ACTION] User requested a recall",
  MY0006: "[YOUR] [FE] [USER ACTION] User requested a policy extension",
  MY0007: "[YOUR] [FE] [USER ACTION] User requested to repurchase a policy",
  MY0008: "[YOUR] [FE] [USER ACTION] User requested a new quote",
  MY0009: "[YOUR] [FE] [USER ACTION] User logged out (session expired)",
  MY0010:
    "[YOUR] [FE] [API RESULT] Users request to download a document failed",
  MY0011:
    "[YOUR] [FE] [API RESULT] Users request to download a document was successful",
  MY0012: "[YOUR] [FE] [API RESULT] Users request to recall failed",
  MY0013: "[YOUR] [FE] [API RESULT] Users request to recall was successful",
  MY0014: "[YOUR] [FE] [API RESULT] Users request to extend a policy failed",
  MY0015:
    "[YOUR] [FE] [API RESULT] Users request to extend a policy was successful",
  MY0016:
    "[YOUR] [FE] [API RESULT] Users request to repurchase a policy failed",
  MY0017:
    "[YOUR] [FE] [API RESULT] Users request to repurchase a policy was successful",
  MY0018: "[YOUR] [FE] [API RESULT] Users request for a new quote failed",
  MY0019:
    "[YOUR] [FE] [API RESULT] Users request for a new quote was successful",
  MY0020: "[YOUR] [FE] [USER ACTION] User requested to view policy details",
  MY0021: "[YOUR] [FE] [API RESULT] Users request to view a policy failed",
  MY0022:
    "[YOUR] [FE] [API RESULT] Users request to view a policy was successful",
  MY0023: "[YOUR] [FE] [API RESULT] Users document has been marked as read",
  MY0036: "[YOUR] [FE] [USER ACTION] User requested to log in from OTT code",
  MY0037:
    "[YOUR] [FE] [API RESULT] Users request to get details from OTT code was successful",
  MY0038:
    "[YOUR] [FE] [API RESULT] Users request to get details from OTT code failed",
  MY0039:
    "[YOUR] [FE] [API RESULT] Users request to get details from OTT has expired or could not be found",
  MY0040: "[YOUR] [FE] [USER ACTION] User opened the quotes list",
  MY0041: "[YOUR] [FE] [USER ACTION] User opened the policies list",
};
type EventCode = keyof typeof eventSummaries;

export default function useApi() {
  const sessionIdStore = useSessionIdStore();
  const customerStore = useCustomerStore();

  const { checkLoginData } = useHelpers();
  const { setSession, clearSession } = useStorage();

  const postCloudLog = async (
    eventMessage: string,
    eventEmail: string,
    eventType: "INFO" | "ERROR",
    eventUID: string | null,
    eventData?: object
  ) => {
    if (useRuntimeConfig().public.ghost === 1) {
      return;
    }
    try {
      const { success } = await $fetch("/api/goshorty/post/cloudlog/", {
        method: "POST",
        body: {
          logMessage: eventMessage,
          logEmail: eventEmail,
          logData: eventData,
          logType: eventType,
          logUID: eventUID,
        },
      });

      if (!success) {
        return {
          success: false,
        };
      }

      return { success: true };
    } catch (err) {
      return {
        success: false,
        error: err,
      };
    }
  };

  const postLog = async (
    eventCode: EventCode,
    eventEmail: string,
    eventType: "INFO" | "ERROR",
    eventUID: string | null,
    eventData?: object
  ) => {
    if (useRuntimeConfig().public.ghost === 1) {
      return;
    }
    if (!(eventCode in eventSummaries)) {
      return {
        success: false,
        error: "Incorrect event code specified.",
      };
    }

    try {
      const { success, data } = await $fetch("/api/goshorty/post/log/", {
        method: "POST",
        body: {
          session_id: sessionIdStore.getSessionId(),
          event_code: eventCode,
          event_summary: eventSummaries[eventCode],
          event_data: eventData ?? {},
          event_uid: eventUID ?? null,
        },
      });

      if (!success || !data.accepted) {
        return {
          success: false,
        };
      }

      postCloudLog(
        eventSummaries[eventCode],
        eventEmail,
        eventType,
        eventUID,
        eventData ?? {}
      );

      return { success: true };
    } catch (err) {
      return {
        success: false,
        error: err,
      };
    }
  };

  const getCustomerDetails = async (loginData: object) => {
    const formattedLoginData = checkLoginData(loginData);
    if (!formattedLoginData.passed) return { success: false };

    try {
      postLog(
        "MY0000",
        formattedLoginData.formattedLoginData?.email,
        "INFO",
        null,
        {
          varchar_one: formattedLoginData.formattedLoginData?.email,
        }
      );

      const {
        success: countSuccess,
        data: countData,
        error: countError,
      } = await $fetch("/api/customer/get/recordcount/", {
        method: "POST",
        body: {
          session_id: sessionIdStore.getSessionId(),
          ...formattedLoginData.formattedLoginData,
        },
      });

      postCloudLog(
        "[YOUR] [FE] [API RESULT] " + JSON.stringify(countData),
        formattedLoginData.formattedLoginData?.email,
        "INFO",
        null
      );

      if (!countSuccess) {
        postLog(
          "MY0001",
          formattedLoginData.formattedLoginData?.email,
          "ERROR",
          null,
          {
            varchar_one: formattedLoginData.formattedLoginData?.email,
            varchar_two:
              "API execution error (count success): " + countError.message,
          }
        );
      }

      const {
        success: listSuccess,
        data: listData,
        error: listError,
      } = await $fetch("/api/customer/get/recordlist/", {
        method: "POST",
        body: {
          session_id: sessionIdStore.getSessionId(),
          ...formattedLoginData.formattedLoginData,
        },
      });

      postCloudLog(
        "[YOUR] [FE] [API RESULT] " + JSON.stringify(listData),
        formattedLoginData.formattedLoginData?.email,
        "INFO",
        null
      );

      if (!listSuccess) {
        postLog(
          "MY0001",
          formattedLoginData.formattedLoginData?.email,
          "ERROR",
          null,
          {
            varchar_one: formattedLoginData.formattedLoginData?.email,
            varchar_two:
              "API execution error (list success): " + listError.message,
          }
        );
      }

      if (
        !countData.records_found ||
        (listData.records.quotes.length === 0 &&
          listData.records.sales.length === 0)
      ) {
        postLog(
          "MY0001",
          formattedLoginData.formattedLoginData?.email,
          "INFO",
          null,
          {
            varchar_one: formattedLoginData.formattedLoginData?.email,
            varchar_two: "No quotes or sales found",
          }
        );
        return {
          success: false,
        };
      }

      // Set the user store so that data is accessible throughout the application...
      customerStore.setCustomerLoginDetails(
        formattedLoginData.formattedLoginData
      );
      customerStore.setCustomerCounts(countData);
      customerStore.setCustomerLists(listData);
      customerStore.setCustomerFound(true);

      // Persist the data for next log in, if requested...
      if (formattedLoginData.formattedLoginData?.remember) {
        setSession(formattedLoginData.formattedLoginData);
      }

      postLog(
        "MY0002",
        formattedLoginData.formattedLoginData?.email,
        "INFO",
        customerStore.customerUID,
        {
          varchar_one: formattedLoginData.formattedLoginData?.email,
          int_one: countData.quotes,
          int_two: countData.sales,
          int_three: countData.total,
        }
      );

      return { success: true };
    } catch (err) {
      postLog(
        "MY0001",
        formattedLoginData.formattedLoginData?.email,
        "ERROR",
        null,
        {
          varchar_one: formattedLoginData.formattedLoginData?.email,
          varchar_two: "API execution error (try/catch): " + err.message,
        }
      );
      return {
        success: false,
        error: err,
      };
    }
  };

  const getRecordDetails = async (
    recordId: string,
    typeValue: "Quote" | "Sale"
  ) => {
    const formattedLoginData = checkLoginData(
      customerStore.customerLoginDetails
    );
    if (!formattedLoginData.passed) return { success: false };

    try {
      postLog(
        "MY0020",
        formattedLoginData.formattedLoginData?.email,
        "INFO",
        customerStore.customerUID,
        {
          varchar_one: formattedLoginData.formattedLoginData?.email,
          int_one: recordId,
        }
      );

      const { success, data, error } = await $fetch(
        "/api/customer/get/record/",
        {
          method: "POST",
          body: {
            session_id: sessionIdStore.getSessionId(),
            ...formattedLoginData.formattedLoginData,
            type: typeValue,
            table_record_id: recordId,
          },
        }
      );

      if (!success) {
        postLog(
          "MY0021",
          formattedLoginData.formattedLoginData?.email,
          "ERROR",
          customerStore.customerUID,
          {
            varchar_one: formattedLoginData.formattedLoginData?.email,
            varchar_two:
              "API execution error (record success): " + error.message,
            int_one: recordId,
          }
        );
      }

      if (Object.keys(data.record).length < 1) {
        postLog(
          "MY0021",
          formattedLoginData.formattedLoginData?.email,
          "INFO",
          customerStore.customerUID,
          {
            varchar_one: formattedLoginData.formattedLoginData?.email,
            varchar_two: "No policy information was returned",
            int_one: recordId,
          }
        );
        return {
          success: false,
        };
      }

      postLog(
        "MY0022",
        formattedLoginData.formattedLoginData?.email,
        "INFO",
        customerStore.customerUID,
        {
          varchar_one: formattedLoginData.formattedLoginData?.email,
          varchar_two: data.record.policy_number,
          int_one: recordId,
        }
      );
      return { success: true, data: data.record };
    } catch (err) {
      postLog(
        "MY0021",
        formattedLoginData.formattedLoginData?.email,
        "ERROR",
        customerStore.customerUID,
        {
          varchar_one: formattedLoginData.formattedLoginData?.email,
          varchar_two: "API execution error (try/catch): " + err.message,
          int_one: recordId,
        }
      );
      return {
        success: false,
        error: err,
      };
    }
  };

  const getDocuments = async (
    tableRecordID: string,
    policyId: string,
    proposerId: string
  ) => {
    const formattedLoginData = checkLoginData(
      customerStore.customerLoginDetails
    );
    if (!formattedLoginData.passed) return { success: false };

    try {
      const { success, data } = await $fetch("/api/customer/get/documents/", {
        method: "POST",
        body: {
          session_id: sessionIdStore.getSessionId(),
          ...formattedLoginData.formattedLoginData,
          table_record_id: tableRecordID,
          policy_id: policyId,
          proposer_id: proposerId,
        },
      });

      if (
        !success ||
        !data.documents.policyDocument.businessEventDocument.document.length
      ) {
        return {
          success: false,
        };
      }
      return {
        success: true,
        data: data.documents.policyDocument.businessEventDocument.document,
      };
    } catch (err) {
      return {
        success: false,
        error: err,
      };
    }
  };

  const markDocumentAsRead = async (
    policyId: string,
    proposerId: string,
    policyNumber: string,
    documentId: string,
    documentName: string
  ) => {
    if (useRuntimeConfig().public.ghost === 1) {
      return;
    }
    const formattedLoginData = checkLoginData(
      customerStore.customerLoginDetails
    );
    if (!formattedLoginData.passed) return { success: false };

    try {
      const { success: documentReadSuccess, data: documentReadData } =
        await $fetch("/api/customer/patch/documentread/", {
          method: "POST",
          body: {
            session_id: sessionIdStore.getSessionId(),
            policy_id: policyId,
            proposer_id: proposerId,
            document_id: documentId,
          },
        });

      if (!documentReadSuccess || documentReadData.accepted !== "True") {
        postLog(
          "MY0010",
          formattedLoginData.formattedLoginData?.email,
          "INFO",
          customerStore.customerUID,
          {
            int_one: policyId,
            int_two: proposerId,
            int_three: documentId,
            varchar_one: formattedLoginData.formattedLoginData?.email,
            varchar_two: `${documentName} (${policyNumber})`,
            varchar_three: "Error marking the document as read",
          }
        );
        return {
          success: false,
          error:
            "There was an error marking the document as read, document has been shown.",
        };
      }

      postLog(
        "MY0023",
        formattedLoginData.formattedLoginData?.email,
        "INFO",
        customerStore.customerUID,
        {
          int_one: policyId,
          int_two: proposerId,
          int_three: documentId,
          varchar_one: formattedLoginData.formattedLoginData?.email,
          varchar_two: `${documentName} (${policyNumber})`,
        }
      );
      return {
        success: true,
      };
    } catch (err) {
      postLog(
        "MY0010",
        formattedLoginData.formattedLoginData?.email,
        "ERROR",
        customerStore.customerUID,
        {
          int_one: policyId,
          int_two: proposerId,
          int_three: documentId,
          varchar_one: formattedLoginData.formattedLoginData?.email,
          varchar_two: `${documentName} (${policyNumber})`,
          varchar_three: "API execution error (try/catch): " + err.message,
        }
      );
      return {
        success: false,
        error: err,
      };
    }
  };

  const getDocument = async (
    tableRecordID: string,
    policyId: string,
    policyNumber: string,
    proposerId: string,
    documentId: string,
    documentName: string
  ) => {
    const formattedLoginData = checkLoginData(
      customerStore.customerLoginDetails
    );
    if (!formattedLoginData.passed) return { success: false };

    try {
      postLog(
        "MY0004",
        formattedLoginData.formattedLoginData?.email,
        "INFO",
        customerStore.customerUID,
        {
          int_one: policyId,
          int_two: proposerId,
          int_three: documentId,
          varchar_one: formattedLoginData.formattedLoginData?.email,
          varchar_two: `${documentName} (${policyNumber})`,
        }
      );

      const { success, data, error } = await $fetch(
        "/api/customer/get/document/",
        {
          method: "POST",
          body: {
            session_id: sessionIdStore.getSessionId(),
            ...formattedLoginData.formattedLoginData,
            table_record_id: tableRecordID,
            policy_id: policyId,
            proposer_id: proposerId,
            document_id: documentId,
          },
        }
      );

      if (!success) {
        postLog(
          "MY0010",
          formattedLoginData.formattedLoginData?.email,
          "ERROR",
          customerStore.customerUID,
          {
            int_one: policyId,
            int_two: proposerId,
            int_three: documentId,
            varchar_one: formattedLoginData.formattedLoginData?.email,
            varchar_two: `${documentName} (${policyNumber})`,
            varchar_three:
              "API execution error (document success): " + error.message,
          }
        );
      }

      if (!data.document_base64) {
        postLog(
          "MY0010",
          formattedLoginData.formattedLoginData?.email,
          "INFO",
          customerStore.customerUID,
          {
            int_one: policyId,
            int_two: proposerId,
            int_three: documentId,
            varchar_one: formattedLoginData.formattedLoginData?.email,
            varchar_two: `${documentName} (${policyNumber})`,
            varchar_three: "No document returned from the API",
          }
        );
        return {
          success: false,
          error:
            "There was an error with fetching the document from the API, document has not been show.",
        };
      }

      postLog(
        "MY0011",
        formattedLoginData.formattedLoginData?.email,
        "INFO",
        customerStore.customerUID,
        {
          int_one: policyId,
          int_two: proposerId,
          int_three: documentId,
          varchar_one: formattedLoginData.formattedLoginData?.email,
          varchar_two: `${documentName} (${policyNumber})`,
        }
      );

      return {
        success: true,
        documentBase64: data.document_base64,
      };
    } catch (err) {
      postLog(
        "MY0010",
        formattedLoginData.formattedLoginData?.email,
        "ERROR",
        customerStore.customerUID,
        {
          int_one: policyId,
          int_two: proposerId,
          int_three: documentId,
          varchar_one: formattedLoginData.formattedLoginData?.email,
          varchar_two: `${documentName} (${policyNumber})`,
          varchar_three: "API execution error (try/catch): " + err.message,
        }
      );
      return {
        success: false,
        error: err,
      };
    }
  };

  const forgetCustomerDetails = async () => {
    postLog(
      "MY0003",
      customerStore.customerLoginDetails.email,
      "INFO",
      customerStore.customerUID,
      {
        varchar_one: customerStore.customerLoginDetails.email,
      }
    );
    clearSession();
    customerStore.$reset();
    sessionIdStore.generateSessionId();
    useLinker().pushLink("/");
  };

  const expireCustomersSession = async () => {
    postLog(
      "MY0009",
      customerStore.customerLoginDetails.email,
      "INFO",
      customerStore.customerUID,
      {
        varchar_one: customerStore.customerLoginDetails.email,
      }
    );
    customerStore.$reset();
    sessionIdStore.generateSessionId();
    useLinker().pushLink("/");
  };

  const getLiveLink = async () => {
    try {
      const { success: linkSuccess, data: linkData } = await $fetch(
        "/api/goshorty/get/livelink",
        {
          method: "POST",
          body: {
            session_id: sessionIdStore.getSessionId(),
          },
        }
      );

      if (!linkSuccess || !linkData.value) {
        return {
          success: false,
        };
      }

      return { success: true, liveLink: "https://" + linkData.value };
    } catch (err) {
      return {
        success: false,
        error: err,
      };
    }
  };

  const getMaintenance = async () => {
    try {
      const { success, data } = await $fetch("/api/goshorty/get/maintenance", {
        method: "POST",
        body: {
          session_id: sessionIdStore.getSessionId(),
        },
      });

      if (!success) {
        return {
          success: false,
        };
      }

      const messages = data.maintenance_messages;

      if (
        messages.length &&
        (messages[0].show_in_portal || messages[0].close_portal)
      ) {
        const {
          close_portal,
          headline,
          further_information,
          maintenance_start,
          maintenance_active,
          maintenance_end,
        } = messages[0];

        return {
          success: true,
          data: {
            has_maintenance: true,
            has_started: maintenance_active,
            close_portal,
            headline,
            further_information,
            maintenance_start,
            maintenance_end,
          },
        };
      } else {
        return {
          success: true,
          data: {
            has_maintenance: false,
          },
        };
      }
    } catch (err) {
      return {
        success: false,
        error: err,
      };
    }
  };

  const doRecall = async (
    webreference: string,
    registration: string,
    type: "Sale" | "Quote",
    options: {
      regoverride: false | string;
      logType: "Recall" | "Repurchase" | "New";
    }
  ) => {
    let logCodes = {
      action: "MY0005" as EventCode,
      success: "MY0013" as EventCode,
      fail: "MY0012" as EventCode,
    };
    if (options.logType === "Repurchase") {
      logCodes.action = "MY0007";
      logCodes.success = "MY0017";
      logCodes.fail = "MY0016";
    }
    if (options.logType === "New") {
      logCodes.action = "MY0008";
      logCodes.success = "MY0019";
      logCodes.fail = "MY0018";
    }

    try {
      postLog(
        logCodes.action,
        customerStore.customerLoginDetails.email,
        "INFO",
        customerStore.customerUID,
        {
          varchar_one: customerStore.customerLoginDetails.email,
          varchar_two: webreference,
          varchar_three: registration,
        }
      );

      const {
        success: ottSuccess,
        data: ottData,
        error: ottError,
      } = await $fetch("/api/goshorty/get/ott", {
        method: "POST",
        body: {
          session_id: sessionIdStore.getSessionId(),
          web_reference: webreference,
          type,
        },
      });

      if (!ottSuccess) {
        postLog(
          logCodes.fail,
          customerStore.customerLoginDetails.email,
          "ERROR",
          customerStore.customerUID,
          {
            varchar_one: customerStore.customerLoginDetails.email,
            varchar_two: webreference,
            varchar_three:
              "API execution error (ott success): " + ottError.message,
          }
        );
      }

      if (!ottData.record.web_reference) {
        postLog(
          logCodes.fail,
          customerStore.customerLoginDetails.email,
          "INFO",
          customerStore.customerUID,
          {
            varchar_one: customerStore.customerLoginDetails.email,
            varchar_two: webreference,
            varchar_three: "No OTT was recieved from the API",
          }
        );
        return {
          success: false,
        };
      }

      let locationToOpen = "/quote-recall/?secret=" + ottData.ott;
      if (options.regoverride) {
        locationToOpen = locationToOpen + "&regoverride=" + options.regoverride;
      }

      postLog(
        logCodes.success,
        customerStore.customerLoginDetails.email,
        "INFO",
        customerStore.customerUID,
        {
          varchar_one: customerStore.customerLoginDetails.email,
          varchar_two: webreference,
          varchar_three: registration,
        }
      );
      return { success: true, locationToOpen };
    } catch (err) {
      postLog(
        logCodes.fail,
        customerStore.customerLoginDetails.email,
        "ERROR",
        customerStore.customerUID,
        {
          varchar_one: customerStore.customerLoginDetails.email,
          varchar_two: webreference,
          varchar_three: "API execution error (try/catch): " + err.message,
        }
      );
      return {
        success: false,
        error: err,
      };
    }
  };

  const doExtend = async (
    webreference: string,
    registration: string,
    type: "Sale" | "Quote",
    options: { regoverride: false | string }
  ) => {
    try {
      postLog(
        "MY0006",
        customerStore.customerLoginDetails.email,
        "INFO",
        customerStore.customerUID,
        {
          varchar_one: customerStore.customerLoginDetails.email,
          varchar_two: webreference,
          varchar_three: registration,
        }
      );

      const {
        success: ottSuccess,
        data: ottData,
        error: ottError,
      } = await $fetch("/api/goshorty/get/ott", {
        method: "POST",
        body: {
          session_id: sessionIdStore.getSessionId(),
          web_reference: webreference,
          type,
        },
      });

      if (!ottSuccess) {
        postLog(
          "MY0014",
          customerStore.customerLoginDetails.email,
          "ERROR",
          customerStore.customerUID,
          {
            varchar_one: customerStore.customerLoginDetails.email,
            varchar_two: webreference,
            varchar_three:
              "API execution error (ott success): " + ottError.message,
          }
        );
      }

      if (!ottData.record.web_reference) {
        postLog(
          "MY0014",
          customerStore.customerLoginDetails.email,
          "INFO",
          customerStore.customerUID,
          {
            varchar_one: customerStore.customerLoginDetails.email,
            varchar_two: webreference,
            varchar_three: "No OTT was recieved from the API",
          }
        );
        return {
          success: false,
        };
      }

      let locationToOpen =
        "/quote-recall/?extendpolicy=true&secret=" + ottData.ott;
      if (options.regoverride) {
        locationToOpen = locationToOpen + "&regoverride=" + options.regoverride;
      }

      postLog(
        "MY0015",
        customerStore.customerLoginDetails.email,
        "INFO",
        customerStore.customerUID,
        {
          varchar_one: customerStore.customerLoginDetails.email,
          varchar_two: webreference,
          varchar_three: registration,
        }
      );
      return { success: true, locationToOpen };
    } catch (err) {
      postLog(
        "MY0014",
        customerStore.customerLoginDetails.email,
        "ERROR",
        customerStore.customerUID,
        {
          varchar_one: customerStore.customerLoginDetails.email,
          varchar_two: webreference,
          varchar_three: "API execution error (try/catch): " + err.message,
        }
      );
      return {
        success: false,
        error: err,
      };
    }
  };

  const getDetailsFromOtt = async (ott: string) => {
    try {
      postLog("MY0036", "unknown", "INFO", null, {
        varchar_one: "unknown",
        varchar_two: ott,
      });
      const { success, data, error } = await $fetch(
        "/api/goshorty/get/detailsfromott",
        {
          method: "POST",
          body: {
            session_id: sessionIdStore.getSessionId(),
            ott,
          },
        }
      );
      if (!success) {
        if (data.error_code === 33) {
          postLog("MY0039", "unknown", "ERROR", null, {
            varchar_one: "unknown",
            varchar_two: ott,
          });
        } else {
          postLog("MY0038", "unknown", "ERROR", null, {
            varchar_one: "unknown",
            varchar_two: ott,
            varchar_three:
              "API execution error (details from OTT): " + error?.message,
          });
        }
        return {
          success: false,
        };
      }

      if (!data.record?.ott) {
        postLog("MY0038", "unknown", "ERROR", null, {
          varchar_one: "unknown",
          varchar_two: ott,
          varchar_three: "Unable to get credentials from OTT code",
        });
        return {
          success: false,
        };
      }

      postLog("MY0037", data.record.email, "INFO", null, {
        varchar_one: data.record.email,
        varchar_two: ott,
      });
      return { success: true, data };
    } catch (err) {
      postLog("MY0038", "unknown", "ERROR", null, {
        varchar_one: "unknown",
        varchar_two: ott,
        varchar_three: "API execution error (try/catch): " + err.message,
      });
      return {
        success: false,
        error: err,
      };
    }
  };

  return {
    getCustomerDetails,
    getRecordDetails,
    getDocuments,
    getDocument,
    getDetailsFromOtt,
    forgetCustomerDetails,
    expireCustomersSession,
    markDocumentAsRead,
    doRecall,
    doExtend,
    postLog,
    postCloudLog,
    getLiveLink,
    getMaintenance,
  };
}
