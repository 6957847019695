<template>
  <div>
    <client-only>
      <font-awesome-icon :icon="[props.type, props.icon]" fixed-width />
    </client-only>
  </div>
</template>
<script setup>
  const props = defineProps({
    type: { type: String, required: false, default: "fal" },
    icon: { type: String, required: true, default: "triangle-exclamation" },
  });
</script>
