import { defineStore } from "pinia";

export const useCustomerStore = defineStore("customerStore", {
  state: () => ({
    customerFound: false,
    customerTimeOut: 1,
    customerLoginDetails: {},
    customerFirstname: "",
    customerTitle: "",
    customerSurname: "",
    customerTotalQuotes: 0,
    customerTotalSales: 0,
    customerTotalOverall: 0,
    customerQuotes: [],
    customerSales: [],
    customerUID: null,
  }),
  actions: {
    setCustomerLoginDetails(loginDetails: object) {
      this.customerLoginDetails = loginDetails;
    },
    setCustomerCounts(counts: object) {
      this.customerTotalQuotes = counts.quotes;
      this.customerTotalSales = counts.sales;
      this.customerTotalOverall = counts.total;
    },
    setCustomerLists(lists: object) {
      this.customerQuotes = lists.records.quotes;
      this.customerSales = lists.records.sales;
      this.setCustomerName();
      this.setCustomerUID();
    },
    setCustomerFound(found: boolean) {
      const config = useRuntimeConfig();

      this.customerFound = found;
      this.customerTimeOut =
        new Date().getTime() + config.public.sessionTimeoutMinutes * 60 * 1000;
    },
    setCustomerName() {
      let customerDetails = null;
      if (this.customerQuotes.length) {
        customerDetails = this.customerQuotes[0];
      } else if (this.customerSales.length) {
        customerDetails = this.customerSales[0];
      }
      if (customerDetails) {
        this.customerTitle = customerDetails.proposer_name_title;
        this.customerFirstname = customerDetails.proposer_name_forenames;
        this.customerSurname = customerDetails.proposer_name_surname;
      }
    },
    setCustomerUID() {
      let customerDetails = null;
      if (this.customerQuotes.length) {
        customerDetails = this.customerQuotes[0];
      } else if (this.customerSales.length) {
        customerDetails = this.customerSales[0];
      }
      if (customerDetails) {
        this.customerUID = customerDetails.unique_customer_identifier;
      }
    },
    getAllQuotes() {
      return this.customerQuotes;
    },
    getAllSales() {
      return this.customerSales;
    },
    getActiveSales() {
      return this.customerSales.filter((s) => {
        return s.has_expired === false;
      });
    },
    getExpiredSales() {
      return this.customerSales.filter((s) => {
        return s.has_expired === true;
      });
    },
    getSaleById(id: number) {
      const sale = this.customerSales.filter((s) => {
        return s.id === id;
      });
      if (sale.length > 0) {
        return sale[0];
      } else {
        return false;
      }
    },
    getLatestQuote() {
      if (this.customerQuotes.length) return this.customerQuotes[0];
      return [];
    },
    getQuoteById(id: number) {
      const quote = this.customerQuotes.filter((q) => {
        return q.id === id;
      });
      if (quote.length > 0) {
        return quote[0];
      } else {
        return false;
      }
    },
    getAllRegistrations() {
      const regReturn = [];
      this.customerQuotes.forEach((q) => {
        if (!regReturn.includes(q.registration)) {
          regReturn.push(q.registration);
        }
      });
      this.customerSales.forEach((q) => {
        if (!regReturn.includes(q.registration)) {
          regReturn.push(q.registration);
        }
      });
      return regReturn;
    },
  },
});
