import { defineStore } from "pinia";

export const useDateStore = defineStore("dateStore", {
  state: () => ({
    liveDate: 1,
    liveDateStarted: false,
    liveDateInterval: null as NodeJS.Timer | null,
  }),
  actions: {
    startLiveDateInterval() {
      if (!this.liveDateStarted) {
        this.liveDateStarted = true;
        this.liveDateInterval = setInterval(() => {
          this.liveDate = new Date().getTime();
        }, 1000);
      }
    },
    stopLiveDateInterval() {
      if (this.liveDateStarted) {
        this.liveDateStarted = false;
        clearInterval(this.liveDateInterval);
        this.liveDateInterval = null;
      }
    },
  },
});
