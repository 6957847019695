import { defineStore } from "pinia";

export const useSessionIdStore = defineStore("sessionIdStore", {
  state: () => ({
    sessionId: "",
  }),
  actions: {
    generateSessionId() {
      const sections = [
        this.generateRandomSection(),
        this.generateRandomSection(),
        this.generateRandomSection(),
      ];

      this.sessionId = sections.join("-");
    },
    generateRandomSection() {
      const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
      let section = "";

      for (let i = 0; i < 10; i++) {
        const randomIndex = Math.floor(
          (crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) *
            characters.length
        );
        section += characters.charAt(randomIndex);
      }

      return section;
    },
    getSessionId() {
      if (!this.sessionId.length) {
        this.generateSessionId();
      }
      return this.sessionId;
    },
  },
});
