<template>
  <layout-header />
  <div class="container mx-auto my-10">
    <div class="text-5xl text-gs-tarmac text-center">
      <base-icon icon="message-exclamation" type="fad" />
    </div>
    <div
      class="text-center mt-5 max-w-3xl mx-auto px-10 text-4xl font-TTNormsProSerifBold">
      Oops!
    </div>
    <div class="text-center mt-5 max-w-3xl mx-auto px-10">
      We're really sorry, something has gone wrong here. Don't worry, our
      development team has been notified. If you continue to get this error,
      please contact GoShorty at
      <a href="mailto:info@goshorty.co.uk" class="underline font-bold"
        >info@goshorty.co.uk</a
      >.
    </div>
    <div class="text-center mt-5 mx-auto px-10">
      <base-button small-text set-width @click="useLinker().pushLink('/')"
        >Go Home <base-icon icon="arrow-circle-right"
      /></base-button>
    </div>
  </div>
  <navigation-pop-out-menu />
  <layout-footer />
</template>
