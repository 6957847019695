<template>
  <div
    class="base-spinner-style"
    :class="{
      'spinner-style-small': props.small,
      'spinner-style-extra-small': props.extraSmall,
      'spinner-style-dark': props.dark,
      'spinner-style-large': props.large,
    }"
  ></div>
</template>
<script setup>
  const props = defineProps({
    small: { type: Boolean, required: false, default: false },
    extraSmall: { type: Boolean, required: false, default: false },
    large: { type: Boolean, required: false, default: false },
    dark: { type: Boolean, required: false, default: false },
  });
</script>
