<template>
  <div class="footer-main-section">
    <div class="footer-inner-section">
      <div class="footer-grid">
        <div class="footer-left-column">
          <img
            src="/images/logo_main_dark.png"
            class="footer-logo-image"
            alt="Dark version of GoShorty logo"
            @click="goExternal('https://goshorty.co.uk')"
          />
          <base-button
            small-text
            set-width
            @click="goExternal('https://goshorty.co.uk/quote/')"
            >Get a Quote <base-icon icon="arrow-circle-right"
          /></base-button>
        </div>
        <div class="footer-right-column">
          <div class="base-divider" />
          <div class="footer-sub-content-grid">
            <div class="footer-sub-content-grid-left">
              <div class="footer-links">
                <div
                  @click="goExternal('https://goshorty.co.uk/privacy-policy/')"
                >
                  Privacy Policy
                </div>
                <div
                  @click="goExternal('https://goshorty.co.uk/cookie-policy/')"
                >
                  Cookie Policy
                </div>
                <div
                  @click="
                    goExternal('https://goshorty.co.uk/terms-of-business/')
                  "
                >
                  Terms of Business
                </div>
                <div
                  @click="goExternal('https://goshorty.co.uk/terms-of-use/')"
                >
                  Terms of Use
                </div>
              </div>
              <p class="footer-disclaimer-text">
                We are a UK based insurance broker and we are authorised and
                regulated by the Financial Conduct Authority under reference
                number 751221.
              </p>
              <p class="footer-disclaimer-text">
                GoShorty is a registered trademark. GoShorty is a trading style
                of Complex to Clear Group Limited registered in England and
                Wales.
              </p>
              <p class="footer-disclaimer-text">
                Company Registration Number 05044963. Data Protection
                Registration ZA456686.
              </p>
            </div>
            <div class="footer-sub-content-grid-right">
              <img
                src="/images/payment-methods.svg"
                class="footer-payment-image"
                alt="Payment methods image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  const goExternal = (link) => {
    window.open(link, "_blank");
  };
</script>
