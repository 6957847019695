<template>
  <div v-if="globalStateStore.showSideMenu" class="menu-wrapper">
    <div class="menu-header">
      <div>
        MENU
        <base-icon
          icon="xmark"
          class="menu-header-icon"
          @click="globalStateStore.showSideMenu = false"
        />
      </div>
    </div>
    <div
      class="menu-link"
      :class="{
        'menu-link-active':
          useRoute().fullPath === '/portal/' || useRoute().fullPath === '/',
      }"
      @click="goHome"
    >
      <p>Home</p>
    </div>
    <div
      v-if="customerStore.customerFound"
      class="menu-link"
      :class="{
        'menu-link-active': useRoute().fullPath === '/portal/policies',
      }"
      @click="useLinker().pushLink('/portal/policies')"
    >
      <p>Policies</p>
    </div>
    <div
      v-if="customerStore.customerFound"
      class="menu-link"
      :class="{
        'menu-link-active': useRoute().fullPath === '/portal/quotes',
      }"
      @click="useLinker().pushLink('/portal/quotes')"
    >
      <p>Quotes</p>
    </div>
    <div class="menu-buttons">
      <base-button
        v-if="customerStore.customerFound"
        full-width
        small-text
        @click="
          closeMenu();
          scrollToQuotePanel();
        "
        >Get a Quote <base-icon icon="arrow-circle-right"
      /></base-button>
      <base-button
        v-else
        full-width
        small-text
        @click="
          goExternal('https://goshorty.co.uk/quote/');
          closeMenu();
        "
        >Get a Quote <base-icon icon="arrow-circle-right"
      /></base-button>
      <base-button
        full-width
        small-text
        @click="
          goExternal('https://goshorty.co.uk/help-centre/');
          closeMenu();
        "
        >Help Centre <base-icon icon="arrow-circle-right"
      /></base-button>
      <base-button
        full-width
        small-text
        @click="
          goExternal('https://goshorty.co.uk/contact/');
          closeMenu();
        "
        >Contact Us <base-icon icon="arrow-circle-right"
      /></base-button>
      <base-button
        v-if="customerStore.customerFound"
        full-width
        small-text
        theme="dark"
        @click="
          closeMenu();
          forgetCustomerDetails();
        "
        >Logout <base-icon icon="right-from-bracket"
      /></base-button>
    </div>
  </div>
</template>
<script setup>
  import { useGlobalStateStore } from "~/stores/useGlobalStateStore";
  import { useCustomerStore } from "~/stores/useCustomerStore";

  const globalStateStore = useGlobalStateStore();
  const customerStore = useCustomerStore();

  const { forgetCustomerDetails } = useApi();

  const goExternal = (link) => {
    window.open(link, "_blank");
  };

  const closeMenu = () => {
    globalStateStore.showSideMenu = false;
  };

  const goHome = () => {
    if (customerStore.customerFound) {
      useLinker().pushLink("/portal/");
      return;
    }
    useLinker().pushLink("/");
  };

  const scrollToQuotePanel = () => {
    const quotePanel = document.getElementById("new-quote-panel");
    if (quotePanel) {
      quotePanel.scrollIntoView({ behavior: "smooth" });
    }
  };
</script>
