<template>
  <base-icon
    icon="bars"
    class="menu-icon"
    @click="globalStateStore.showSideMenu = true"
  />
</template>
<script setup>
  import { useGlobalStateStore } from "~/stores/useGlobalStateStore";
  const globalStateStore = useGlobalStateStore();
</script>
