import nuxtStorage from "nuxt-storage";

export default function useStorage() {
  const setSession = (sessionData: object) => {
    nuxtStorage.localStorage.setData("goshorty:session", sessionData, 30, "d");
  };

  const getSession = () => {
    return nuxtStorage.localStorage.getData("goshorty:session");
  };

  const clearSession = () => {
    nuxtStorage.localStorage.removeItem("goshorty:session");
  };

  return {
    setSession,
    getSession,
    clearSession,
  };
}
