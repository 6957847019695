import { useDateStore } from "~/stores/useDateStore";
import { useCustomerStore } from "~/stores/useCustomerStore";

export default function useSessionTimeout() {
  const config = useRuntimeConfig();
  const dateStore = useDateStore();
  const customerStore = useCustomerStore();

  const { formatTimeRemaining } = useHelpers();

  const updateTimeOut = () => {
    customerStore.customerTimeOut =
      new Date().getTime() + config.public.sessionTimeoutMinutes * 60 * 1000;
  };

  const getTimeRemaining = () => {
    const timeDifference = customerStore.customerTimeOut - dateStore.liveDate;
    return formatTimeRemaining(timeDifference);
  };

  const hasTimedOut = () => {
    return (
      customerStore.customerFound &&
      customerStore.customerTimeOut <= dateStore.liveDate
    );
  };

  const hasWarning = () => {
    const timeDifference = customerStore.customerTimeOut - dateStore.liveDate;
    return customerStore.customerFound && timeDifference < 2 * 60 * 1000;
  };

  return {
    updateTimeOut,
    getTimeRemaining,
    hasTimedOut,
    hasWarning,
  };
}
