import { useDateStore } from "~/stores/useDateStore";

export default function useHelpers() {
  const dateStore = useDateStore();

  const checkSurname = (surname: string) => {
    return surname.length > 0;
  };

  const checkPostcode = (postcode: string) => {
    return postcode.length > 0;
  };

  const checkEmail = (email: string) => {
    return (
      email.length > 2 &&
      /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email)
    );
  };

  const checkDateOfBirth = (dob: string) => {
    const dateFormatRegex = /^(0[1-9]|[1-2]\d|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;
    if (!dateFormatRegex.test(dob)) {
      return false;
    }

    const parts = dob.split("/");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    const parsedDate = new Date(year, month, day);

    return (
      parsedDate.getDate() == day &&
      parsedDate.getMonth() == month &&
      parsedDate.getFullYear() == year
    );
  };

  const formatLoginData = (loginData: any) => {
    return {
      email: loginData.email.replace(/\s/g, ""),
      surname: loginData.surname.trim(),
      dateofbirth: loginData.dateofbirth.replace(/\s/g, ""),
      postcode: loginData.postcode.replace(/\s/g, ""),
      remember: loginData.remember,
    };
  };

  const formatTimeRemaining = (timeDifference: number) => {
    if (timeDifference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        humanized: "0s",
      };
    }

    const timeUnits = [
      { unit: "d", divisor: 1000 * 60 * 60 * 24 },
      { unit: "h", divisor: 1000 * 60 * 60 },
      { unit: "m", divisor: 1000 * 60 },
      { unit: "s", divisor: 1000 },
    ];

    let humanized = "";
    let remainingTime = timeDifference;

    for (const { unit, divisor } of timeUnits) {
      const value = Math.floor(remainingTime / divisor);

      if (value > 0 || unit === "s" || humanized) {
        humanized += `${value}${unit} `;
        remainingTime %= divisor;
      }
    }

    return {
      days: Math.floor(timeDifference / (1000 * 60 * 60 * 24)),
      hours: Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      ),
      minutes: Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((timeDifference % (1000 * 60)) / 1000),
      humanized: humanized.trim(),
    };
  };

  const formatDateOfBirth = (e: KeyboardEvent) => {
    if (!e.target) {
      return;
    }

    const input = e.target as HTMLInputElement;
    const valueToCheck = input.value;
    let sanitizedValue = sanitizeInput(valueToCheck);

    if (isBackspaceOrTab(e)) {
      input.value = sanitizedValue;
      return;
    }

    if (isInvalidKey(e, sanitizedValue)) {
      e.preventDefault();
      return;
    }

    if (isSlashKey(e)) {
      handleSlashKey(e, sanitizedValue, input);
      return;
    }

    insertSlash(sanitizedValue, input);
  };

  const sanitizeInput = (value: string): string => {
    return value.replace(/[^0-9/]/g, "");
  };

  const isBackspaceOrTab = (e: KeyboardEvent): boolean => {
    return e.key === "Backspace" || e.key === "Tab";
  };

  const isInvalidKey = (e: KeyboardEvent, sanitizedValue: string): boolean => {
    return !/^[0-9/]$/i.test(e.key) || sanitizedValue.length === 10;
  };

  const isSlashKey = (e: KeyboardEvent): boolean => {
    return e.key === "/" || e.key === "Slash";
  };

  const handleSlashKey = (
    e: KeyboardEvent,
    sanitizedValue: string,
    input: HTMLInputElement
  ) => {
    if (
      [0, 3].includes(sanitizedValue.length) ||
      (sanitizedValue.match(/\//g) || []).length === 2
    ) {
      e.preventDefault();
    }

    if ([1, 4].includes(sanitizedValue.length)) {
      input.value =
        sanitizedValue.length === 1
          ? "0" + sanitizedValue
          : sanitizedValue.slice(0, 3) + "0" + sanitizedValue.slice(3);
    }
  };

  const insertSlash = (sanitizedValue: string, input: HTMLInputElement) => {
    const positionsToAddSlash = [2, 5];
    sanitizedValue = positionsToAddSlash.reduce((value, position) => {
      if (value.length >= position && value.charAt(position) !== "/") {
        return value.slice(0, position) + "/" + value.slice(position);
      }
      return value;
    }, sanitizedValue);

    input.value = sanitizedValue;
  };

  const getTimeBeforeStart = (
    dateStringFrom: string,
    needsConversion = true
  ) => {
    if (!dateStringFrom) {
      return false;
    }

    let timeUntilEnd;

    if (needsConversion) {
      const [dd, mm, yyyy, HH, nn, ss] = dateStringFrom.split(/[/ :]+/);
      timeUntilEnd = new Date(yyyy, mm - 1, dd, HH, nn, ss).getTime();
    } else {
      timeUntilEnd = new Date(dateStringFrom).getTime();
    }

    const timeDifference = timeUntilEnd - dateStore.liveDate;

    return formatTimeRemaining(timeDifference);
  };

  const getTimeRemaining = (dateStringTo: string, needsConversion = true) => {
    if (!dateStringTo) {
      return false;
    }

    let timeUntilEnd;

    if (needsConversion) {
      const [dd, mm, yyyy, HH, nn, ss] = dateStringTo.split(/[/ :]+/);
      timeUntilEnd = new Date(yyyy, mm - 1, dd, HH, nn, ss).getTime();
    } else {
      timeUntilEnd = new Date(dateStringTo).getTime();
    }

    const timeDifference = timeUntilEnd - dateStore.liveDate;

    return formatTimeRemaining(timeDifference);
  };

  const checkLoginData = (loginData: any) => {
    const formattedLoginData = formatLoginData(loginData);
    let errors = {};

    if (!checkSurname(formattedLoginData.surname))
      errors = { ...errors, surname: true };

    if (!checkPostcode(formattedLoginData.postcode))
      errors = { ...errors, postcode: true };

    if (!checkEmail(formattedLoginData.email))
      errors = { ...errors, email: true };

    if (!checkDateOfBirth(formattedLoginData.dateofbirth))
      errors = { ...errors, dateofbirth: true };

    if (Object.keys(errors).length) {
      return {
        passed: false,
        errors,
      };
    } else {
      return {
        passed: true,
        formattedLoginData,
      };
    }
  };

  const liveDate = () => {
    return new Date(dateStore.liveDate).toLocaleString("en-GB", {
      timeZone: "Europe/London",
    });
  };

  return {
    formatLoginData,
    checkLoginData,
    checkEmail,
    checkDateOfBirth,
    checkSurname,
    checkPostcode,
    getTimeRemaining,
    getTimeBeforeStart,
    formatTimeRemaining,
    formatDateOfBirth,
    liveDate,
  };
}
