<template>
  <div class="header-wrapper">
    <layout-header-bar />
    <div class="header-wrapper-inner">
      <div class="w-[90px] md:hidden">
        <img
          src="/images/quote-icon.svg"
          @click="goNewQuote"
          alt="New Quote CTA"
          class="h-14"
        />
      </div>
      <img
        src="/images/yourgoshorty-logo.svg"
        @click="goHome"
        alt="Light version of GoShorty logo."
      />
      <div class="w-[90px] text-right">
        <navigation-menu-icon />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCustomerStore } from "~/stores/useCustomerStore";

const customerStore = useCustomerStore();
const goHome = () => {
  if (customerStore.customerFound) {
    useLinker().pushLink("/portal/");
    return;
  }
  useLinker().pushLink("/");
};

const goNewQuote = () => {
  if (customerStore.customerFound) {
    const quotePanel = document.getElementById("new-quote-panel");
    if (quotePanel) {
      quotePanel.scrollIntoView({ behavior: "smooth" });
    }
  } else {
    window.open("https://goshorty.co.uk/quote/");
  }
};
</script>
