import { default as indexpdt5XylHifMeta } from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/pages/index.vue?macro=true";
import { default as indexcHt2kq0mcVMeta } from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/pages/portal/index.vue?macro=true";
import { default as _91policyId_93bWt67BmPhMMeta } from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/pages/portal/policies/[policyId].vue?macro=true";
import { default as indexeUy7wdOIB8Meta } from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/pages/portal/policies/index.vue?macro=true";
import { default as index1YoLZWowugMeta } from "/var/www/vhosts/your.goshorty.co.uk/httpdocs/pages/portal/quotes/index.vue?macro=true";
export default [
  {
    name: indexpdt5XylHifMeta?.name ?? "index",
    path: indexpdt5XylHifMeta?.path ?? "/",
    meta: indexpdt5XylHifMeta || {},
    alias: indexpdt5XylHifMeta?.alias || [],
    redirect: indexpdt5XylHifMeta?.redirect,
    component: () => import("/var/www/vhosts/your.goshorty.co.uk/httpdocs/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexcHt2kq0mcVMeta?.name ?? "portal",
    path: indexcHt2kq0mcVMeta?.path ?? "/portal",
    meta: indexcHt2kq0mcVMeta || {},
    alias: indexcHt2kq0mcVMeta?.alias || [],
    redirect: indexcHt2kq0mcVMeta?.redirect,
    component: () => import("/var/www/vhosts/your.goshorty.co.uk/httpdocs/pages/portal/index.vue").then(m => m.default || m)
  },
  {
    name: _91policyId_93bWt67BmPhMMeta?.name ?? "portal-policies-policyId",
    path: _91policyId_93bWt67BmPhMMeta?.path ?? "/portal/policies/:policyId()",
    meta: _91policyId_93bWt67BmPhMMeta || {},
    alias: _91policyId_93bWt67BmPhMMeta?.alias || [],
    redirect: _91policyId_93bWt67BmPhMMeta?.redirect,
    component: () => import("/var/www/vhosts/your.goshorty.co.uk/httpdocs/pages/portal/policies/[policyId].vue").then(m => m.default || m)
  },
  {
    name: indexeUy7wdOIB8Meta?.name ?? "portal-policies",
    path: indexeUy7wdOIB8Meta?.path ?? "/portal/policies",
    meta: indexeUy7wdOIB8Meta || {},
    alias: indexeUy7wdOIB8Meta?.alias || [],
    redirect: indexeUy7wdOIB8Meta?.redirect,
    component: () => import("/var/www/vhosts/your.goshorty.co.uk/httpdocs/pages/portal/policies/index.vue").then(m => m.default || m)
  },
  {
    name: index1YoLZWowugMeta?.name ?? "portal-quotes",
    path: index1YoLZWowugMeta?.path ?? "/portal/quotes",
    meta: index1YoLZWowugMeta || {},
    alias: index1YoLZWowugMeta?.alias || [],
    redirect: index1YoLZWowugMeta?.redirect,
    component: () => import("/var/www/vhosts/your.goshorty.co.uk/httpdocs/pages/portal/quotes/index.vue").then(m => m.default || m)
  }
]