<template>
  <button
    :disabled="props.disabled || props.loading"
    class="base-button"
    :class="{
      ['button-style-' + props.theme.toLowerCase()]: true,
      ['button-style-' + props.theme.toLowerCase() + '-active']: props.active,
      'base-button-set-width': props.setWidth,
      'base-button-full-width': props.fullWidth,
      'base-button-small-text': props.smallText,
      'base-button-extra-small-text': props.extraSmallText,
      'base-button-loading': props.loading,
      'base-button-small': props.small,
    }"
    @click="
      updateTimeOut();
      $emit('click');
    "
  >
    <div v-if="props.loading">
      <base-spinner
        :small="props.smallText"
        :extra-small="props.extraSmallText"
        :dark="props.darkSpinner"
      />
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </button>
</template>
<script setup>
  const props = defineProps({
    theme: { type: String, required: false, default: "primary" },
    disabled: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: false, default: false },
    active: { type: Boolean, required: false, default: false },
    fullWidth: { type: Boolean, required: false, default: false },
    setWidth: { type: Boolean, required: false, default: false },
    smallText: { type: Boolean, required: false, default: false },
    extraSmallText: { type: Boolean, required: false, default: false },
    transparent: { type: Boolean, required: false, default: false },
    darkSpinner: { type: Boolean, required: false, default: false },
    small: { type: Boolean, required: false, default: false },
  });

  const emits = defineEmits(["click"]);

  const { updateTimeOut } = useSessionTimeout();
</script>
