import * as Sentry from "@sentry/vue";
import type { Router } from "vue-router";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();

  Sentry.init({
    dsn: config.public.sentryDSN,
    app: nuxtApp.vueApp,
    debug: config.public.environment !== "production",
    release: config.public.release,
    autoSessionTracking: true,
    trackComponents: true,
    environment: config.public.environment,
    hooks: ["activate", "create", "destroy", "mount", "update"],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(
          nuxtApp.$router as Router
        ),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  };
});
