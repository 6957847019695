import { useLinkerStore } from "@/stores/useLinkerStore";
import { useToast } from "vue-toastification";

export default function useSessionTimeout() {
  const linkerStore = useLinkerStore();
  const { doRecall, doExtend, getLiveLink } = useApi();

  const addQueryToUrl = (link: string) => {
    const route = useRoute();
    const currentQuery = { ...route.query };
    delete currentQuery.ott;

    const urlSearchParams = new URLSearchParams();

    Object.entries(currentQuery).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v) => urlSearchParams.append(key, v as string));
      } else if (value !== null && value !== undefined) {
        urlSearchParams.append(key, value as string);
      }
    });

    const queryString = urlSearchParams.toString();
    const separator = link.includes("?") ? "&" : "?";

    return queryString ? `${link}${separator}${queryString}` : link;
  };

  const showLinker = () => {
    linkerStore.linkerModalShow = true;
  };

  const hideLinker = () => {
    linkerStore.linkerModalShow = false;
  };

  const doExtendHandler = async (
    webReference: string,
    registration: string,
    type: "Quote" | "Sale"
  ) => {
    if (!linkerStore.linkerLiveLink.length) {
      useToast().error(
        "There was an error extending your policy, please try again or contact info@goshorty.co.uk."
      );
      linkerStore.linkerModalShow = false;
      return;
    }

    linkerStore.setLinker(webReference, registration, "Extend", type);
    const { success, locationToOpen } = await doExtend(
      linkerStore.linkerWebReference,
      linkerStore.linkerRegistration,
      linkerStore.linkerSubType,
      {
        regoverride: false,
      }
    );

    if (!success) {
      useToast().error(
        "There was an error extending your policy, please try again or contact info@goshorty.co.uk."
      );
      linkerStore.linkerModalShow = false;
    } else {
      const newLink = addQueryToUrl(
        linkerStore.linkerLiveLink + locationToOpen
      );
      linkerStore.linkerFinalLink = newLink;
      linkerStore.linkerFinalLinkGenerated = true;
    }
  };

  const doRecallHandler = async (
    webReference: string,
    registration: string,
    type: "Quote" | "Sale",
    logType: "Recall" | "Repurchase" | "New",
    regoverride: string | false = false
  ) => {
    if (!linkerStore.linkerLiveLink.length) {
      useToast().error(
        "There was an error recalling your policy, please try again or contact info@goshorty.co.uk."
      );
      linkerStore.linkerModalShow = false;
      return;
    }

    linkerStore.setLinker(webReference, registration, "Recall", type);
    const { success, locationToOpen } = await doRecall(
      linkerStore.linkerWebReference,
      linkerStore.linkerRegistration,
      linkerStore.linkerSubType,
      {
        regoverride,
        logType,
      }
    );

    if (!success) {
      useToast().error(
        "There was an error recalling your policy, please try again or contact info@goshorty.co.uk."
      );
      linkerStore.linkerModalShow = false;
    } else {
      const newLink = addQueryToUrl(
        linkerStore.linkerLiveLink + locationToOpen
      );
      linkerStore.linkerFinalLink = newLink;
      linkerStore.linkerFinalLinkGenerated = true;
    }
  };

  const isGenerated = () => {
    return linkerStore.linkerFinalLinkGenerated;
  };

  const showModal = () => {
    return linkerStore.linkerModalShow;
  };

  const getFinalLink = () => {
    return linkerStore.linkerFinalLink;
  };

  const getLiveLinkHandler = async () => {
    const { success, liveLink } = await getLiveLink();
    if (success) {
      linkerStore.linkerLiveLink = liveLink ?? "";
    }
  };

  const pushLink = (link: string) => {
    const newLink = addQueryToUrl(link);
    useRouter().push(newLink);
  };

  return {
    showLinker,
    hideLinker,
    isGenerated,
    doRecallHandler,
    doExtendHandler,
    showModal,
    getFinalLink,
    pushLink,
    getLiveLinkHandler,
  };
}
